import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import  Homepage  from './Homepage';





// import HamburgerMenu from './HumburgerMenu';

import './App.css';

function App() {
  return (
    
    <Router>
      <div className="App">
    
     
        {/* <HamburgerMenu /> */} 
        <Routes>
          <Route path="/" element={<Homepage />} />
       
          </Routes>
     
      </div>
    </Router>
  );
}

export default App;
