import React, { useState, useEffect } from 'react';
import './Homepage.css';
import Logo from './images/cubblogo.png';
import chainimage from './images/cubchain.png';
import btclogo from './images/btclogo.png';
import TwitterLogo from './images/Twitter-X-logo-2023.jpg';
import TelegramLogo from './images/Telegram_logo.svg.webp';
import loadingIcon from './images/load.png'; 
import HamburgerMenu from './images/HumburgerMenu';

const CountdownOverlay = ({ targetDate, onClose }) => {
  const calculateTimeLeft = () => {
    const difference = +new Date(targetDate) - +new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (10000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  });

  

  const countdown = Object.keys(timeLeft).map((interval) => {
    if (!timeLeft[interval]) {
      return null;
    }
  
    return (
      <div className="countdown-timer">
        <span>{timeLeft[interval]}</span>
        <span>{interval}</span>
      </div>
    );
  });

  return (
    <div className="countdown-overlay">
      <div className="countdown-content">
        <h4  className="comingsoon">Coming Soon</h4>
        <div>{countdown.length ? countdown : <span>Time's up!</span>}</div>
        <button onClick={onClose}>X</button>
      </div>
    </div>  );
};


const Homepage = () => {
  const [showCountdown, setShowCountdown] = useState(false);
  const nextYearMarch = new Date(new Date().getFullYear() + 1, 2, 2);
  const [isLoading, setIsLoading] = useState(true); 

  useEffect(() => {
 
    const timer = setTimeout(() => setIsLoading(false), 3000);
    return () => clearTimeout(timer);
  }, []);

  const handleClose = () => {
    setShowCountdown(false);
  };
  
  return (
    <>
    {isLoading && (
        <div className="loading-container">
          <img src={loadingIcon} className="loading-icon" alt="Loading" />
      
        </div>
      )}
  
      <section className="header">
        <nav className="navigation">
          <div className="logo-section">
            <img src={Logo} alt="Company Logo" className="logo" />
            <span className="company-name">CanUBlockchain?</span>
          </div>
      
<HamburgerMenu setShowCountdown={setShowCountdown} />


          <ul className="nav-list">
            <li className="nav-item">Home</li>
            <li className="nav-item" onClick={() => setShowCountdown(true)}>Tours</li>
            <li className="nav-item" onClick={() => window.location.href = 'http://docs.canublockchain.com/'}>Docs</li>
<li className="nav-item" onClick={() => window.location.href = 'https://forum.canublockchain.com/'}>Forum</li>

            
          </ul>
        
          <div className="nav-buttons">
              <button className="login" onClick={() => setShowCountdown(true)}>Sign Up / Login</button>
          </div>
            </nav>
          
        <div className="header-content">
        <div className="header-content">
    <h1 className="welcome-title">Welcome</h1>
    <h3 className="satoshi-title">CanUBlockchain like Satoshi?</h3>
   
</div>
   <p className="intro-text">Join a Challenge and Consensus Tour and Find Out</p>
        {showCountdown && <CountdownOverlay targetDate={nextYearMarch} onClick={handleClose}  />}
      <img src={btclogo} alt="Bitcoin Logo" className="btclogo" />
           <img src={chainimage} alt="BlockchainImage" className="chainimage" />
         
          <h2>A Decentralized Autonomous Competitive Cooperative </h2>
      
          <p className="call-to-action">We are seeking bitcoin & blockchain conversation mappers. Is that you?</p>
          <button className="enterbtn" onClick={() => setShowCountdown(true)}>Enter Dapp</button>
      
        </div>
        <div className="socialmedia">
        <p className="follow-us">Follow us</p>
    <div className="social-links">
        <a href="https://x.com/CanUBlockchain?s=20" target="_blank" rel="noopener noreferrer">
            <img src={TwitterLogo} alt="Twitter" className="social-icon" />
        </a>
        <a href="https://t.me/" target="_blank" rel="noopener noreferrer">
            <img src={TelegramLogo} alt="Telegram" className="social-icon" />
        </a>
           </div>
</div>
      </section>
      {showCountdown && (
        <CountdownOverlay
          targetDate={nextYearMarch}
          onClose={handleClose}
        />
      )}

      <footer className="footer glass">
        <div className="footer-content">
          <div className="contact-info">
            <h3>Contact Us</h3>
            <p className="email-info">
              Email: <a href="mailto:support@canublockchain.com">CanUBlockchain@gmail.com</a>
            </p>
            </div>
        </div>
        <div className="footer-bottom">
          <p>
            &copy; Copyright {new Date().getFullYear()} CanUBlockchain Inc. 
          </p>
        </div>
      </footer>
    </>
  );
};

export default Homepage;
