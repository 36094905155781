import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import './HamburgerMenu.css';
import { NavLink } from 'react-router-dom';

const HamburgerMenu = ({ setShowCountdown }) => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(prevState => !prevState);
  };



  return (
    <>
      <FontAwesomeIcon
        icon={faBars}
        className="hamburger-menu"
        onClick={toggleMenu}
      />
      <div className={`hamburger-menu-content ${menuOpen ? 'active' : ''}`}>
        <ul className={menuOpen ? 'nav-menu active' : 'nav-menu'}>
          <li>
            <NavLink
              exact
              to="/"
              activeClassName="Homebutton"
              onClick={() => { toggleMenu(); setShowCountdown(false); }}
            >
              Home
            </NavLink>
          </li>

          <li className="services-link" >
            <div onClick={() => { setShowCountdown(true); toggleMenu(); }}>Tours</div>
           
          
          
          </li>

          <li className="services-link">
            <div  onClick={() => { setShowCountdown(true); toggleMenu(); }}>Docs</div>
           
          </li>

          <li className="services-link">
            <div  onClick={() => { setShowCountdown(true); toggleMenu(); }}>Forum</div>
           
          </li>

            <button className="btnlogin" onClick={() => { setShowCountdown(true); toggleMenu(); }}>Sign Up / Login</button>
         
        </ul>
      </div>
    </>
  );
};

export default HamburgerMenu;
